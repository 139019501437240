/* eslint react/prop-types: 0 */
import { ActionIcon, Box, Indicator, List, Modal, ScrollArea, Text, Tooltip } from '@mantine/core';
import { IconArchive, IconBriefcase, IconCubeSend, IconEye, IconInfoCircle, IconJumpRope, IconLock, IconPencil, IconReportAnalytics, IconTrash } from '@tabler/icons-react';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getAssessment, getAssessmentPurposes, getAssessments } from '../../../js/api/assessment_repository';
import { getAvailableCompetencies } from '../../../js/api/competency_repository';
import { AssessmentPurpose, assessmentPurposeFromId, idFromAssessmentPurpose } from '../../../js/generated/enums/AssessmentPurpose';
import { AssessmentProtection, idFromProtection } from '../../../js/modules/Build/Assessment/AssessmentProtection';
import { formatToOptions } from '../../../js/util/DataUtil';
import Dropdown from '../../core/Dropdown';
import { Header, NumberCell } from '../../core/ReactTable/ReactTable';
import { getOptionsFromEnumName } from '../../../js/generated/meta/generated';
import { useSelector } from 'react-redux';
import {
  selectAllParamsQueryData,
  selectParamsFilterField
} from '../../core/ReactTable/paramsSlice';
import { useDisclosure } from '@mantine/hooks';
import { useGetAssessmentsQuery, useGetAssessmentTypesQuery } from './redux/AssessmentsApi';
import { apiSlice } from '../../api';
import { generatePath, Link } from 'react-router-dom';
import { CyclePassRoute } from '../../../js/generated/enums/CyclePassRoute';

const PROTECTION_LEVEL_OPTIONS = Object.freeze(Object.values(AssessmentProtection).map(element => {
  return Object.freeze({ label: element, value: idFromProtection(element).toString() })
}))

const ASSESSMENT_PURPOSE_OPTIONS = getOptionsFromEnumName(Object.keys({ AssessmentPurpose })[0]) // [0] === 'AssessmentPurpose'

export function useAssessment (id, params = null) {
  const [assessment, setAssessment] = useState(null)
  const [querying, setQuerying] = useState(1)
  const queryIdRef = useRef(0)

  useEffect(() => {
    const queryId = queryIdRef.current + 1
    queryIdRef.current = queryId
    console.info('Starting assessment query id.', queryId)
    setQuerying(queryId)
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getAssessment(id, params, cancelSource.token)
      .catch(err => {
        console.error('Unexpected request error.', err, id, params)
        return null
      })
      .then(assessment => {
        if (assessment) {
          setAssessment(assessment)
        }
      })
      .finally(() => {
        if (queryIdRef.current === queryId) {
          console.info('Query id ref unchanged - ending querying flag.', queryIdRef.current, queryId)
          setQuerying(prev => prev === queryId ? 0 : prev)
        }
      })

    return () => {
      console.debug('Load assessment data effect unmounted.', id, params, cancelSource)
      cancelSource.cancel()
    }
  }, [id, params])

  return [assessment, querying]
}

export function useAssessmentPurposes () {
  const [purposes, setPurposes] = useState({})

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()

    getAssessmentPurposes(cancelSource.token).then(response => {
      if (response) setPurposes(response)
    })

    return () => cancelSource.cancel()
  }, [])

  return purposes
}

const typesNoData = { hiring: [], skillbuilder: [] }

export function useAssessmentTypes () {
  const { data: types } = useGetAssessmentTypesQuery()
  return types ?? typesNoData
}

export function useAssessmentsQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetAssessmentsQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

export function useLazyAssessmentsQuery (namespace) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getAssessments.useQueryState(queryParams)
  return [collection ?? null, querying]
}

const defaultFilters = { archived: '0' }
const defaultHiddenColumns = ['id']

export function useAssessmentTable (namespace) {
  const columns = useAssessmentColumns()
  const filters = useAssessmentFilters(namespace)
  const [actions, revisionModal] = useAssessmentActions()

  return [{
    defaultHiddenColumns: defaultHiddenColumns,
    defaultFilters: defaultFilters,
    columns: columns,
    filters: filters,
    actions: actions,
    searchable: true
  }, revisionModal]
}

export function useAssessmentFilters (namespace) {
  const rawPurposes = useSelector(state => selectParamsFilterField(state, namespace, 'purpose'))
  const purpose = useMemo(() => {
    return rawPurposes?.constructor === Array ? rawPurposes : (rawPurposes ? [rawPurposes] : [])
  }, [rawPurposes])
  const types = useAssessmentTypes()

  const [hiringTypes, skillbuilderTypes] = useMemo(() => {
    return [formatToOptions(types.hiring, { label: 'internal_name' }), formatToOptions(types.skillbuilder)]
  }, [types])

  return useMemo(() => {
    const HIRING_ID = idFromAssessmentPurpose(AssessmentPurpose.Hiring).toString()
    const SKILLBUILDER_ID = idFromAssessmentPurpose(AssessmentPurpose.SkillBuilder).toString()

    return [
      {
        id: 'purpose',
        label: 'Assessment Purpose',
        leftSection: <IconReportAnalytics />,
        options: ASSESSMENT_PURPOSE_OPTIONS,
        multiSelect: true
      },
      {
        id: 'hiring_type',
        label: 'Hiring Assessment Type',
        leftSection: <IconBriefcase />,
        options: hiringTypes,
        hidden: !purpose?.includes(HIRING_ID),
        multiSelect: true
      },
      {
        id: 'skillbuilder_type',
        label: 'Skillbuilder Assessment Type',
        leftSection: <IconJumpRope />,
        options: skillbuilderTypes,
        hidden: !purpose?.includes(SKILLBUILDER_ID),
        multiSelect: true
      },
      // administration: { // bring this back when we add administration method as a property
      //   id: 'administration',
      //   label: 'Administration Method',
      //   icon: <IconLock />,
      //   options: [
      //     { label: 'Online/Proctored', value: '1' },
      //     { label: 'In-Person/Proctored', value: '1' },
      //     { label: 'Online/Unproctored', value: '0' },
      //     { label: 'Recorded Results', value: '2' },
      //     { label: 'Paper and Pencil', value: '3' }]
      // },
      {
        id: 'protected',
        label: 'Protection',
        leftSection: <IconLock />,
        options: PROTECTION_LEVEL_OPTIONS
      },
      {
        id: 'archived',
        label: 'Status',
        leftSection: <IconArchive />,
        placeholder: 'Showing both',
        options: [{ label: 'Archived', value: '1' }, { label: 'Active', value: '0' }]
      }
    ]
  }, [hiringTypes, skillbuilderTypes, purpose])
}

export function useAssessmentColumns () {
  return useMemo(() => [
    {
      Header: <Header>ID</Header>,
      id: 'id',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return <Text ta='right'>{value}</Text>
      },
      sortable: true,
      hideable: true
    },
    {
      Header: <Header direction='column' gap='xxxs'>
        <Box>Purpose</Box>
        <Box c='dimmed'>Type</Box>
      </Header>,
      headerLabel: 'Purpose/Type',
      id: 'type',
      accessor: 'type',
      Cell: ({ cell: { value, row } }) => {
        const type = row.original.hiring_assessment?.internal_name ?? row.original.skillbuilder_assessment?.name ?? ''
        return (
          <Box>
            <Text>{assessmentPurposeFromId(parseInt(value))}</Text>
            <Text c='dimmed'>{type}</Text>
          </Box>
        )
      },
      hideable: true
    },
    {
      Header: <Header>Name</Header>,
      id: 'name',
      accessor: 'name',
      Cell: ({ cell: { value } }) => {
        return <Text>{value}</Text>
      },
      sortable: true,
      searchable: true
    },
    {
      Header: <Header>Internal Name</Header>,
      id: 'internal_name',
      accessor: 'internal_name',
      Cell: ({ cell: { value } }) => {
        return <Text>{value}</Text>
      },
      sortable: true,
      hideable: true
    },
    {
      Header: <Header>Description</Header>,
      id: 'description',
      accessor: 'description',
      Cell: ({ cell: { value } }) => {
        return <Text>{value}</Text>
      },
      filterable: true,
      hideable: true
    },
    {
      Header: (
        <Header align='center' gap='xs'>
          Protection
          <Dropdown target={<ActionIcon radius='xl' size='sm' color='gray'><IconInfoCircle /></ActionIcon>}>
            <Text><i>Unprotected:</i> The assessment is not proctored or password protected</Text>
            <Text><i>Protected:</i> The assessment is proctored and/or password protected</Text>
            <Text><i>Assessor Records Results:</i> Results are input by an assessor</Text>
          </Dropdown>
        </Header>
      ),
      headerLabel: 'Protection',
      id: 'protected',
      accessor: 'protected',
      Cell: ({ cell: { value } }) => {
        return <Text>{_.find(PROTECTION_LEVEL_OPTIONS, { value: value.toString() }).label}</Text>
      },
      filterable: true,
      hideable: true
    },
    {
      Header: <Header centered><span>Created/<i>Published Date</i></span></Header>,
      id: 'dates',
      accessor: 'created',
      sortingAccessors: [{ id: 'created', label: 'Created' }, { id: 'published', label: 'Published' }],
      Cell: ({ cell: { value, row } }) => {
        const publishedDate = row.original.published_assessments?.[0]?.created ?? null
        const revision = row.original.published_assessments.length
        return (
          <Indicator
            size='lg'
            variant='light'
            color='gray'
            offset='16'
            disabled={revision < 2}
            label={<Tooltip label='Revision #'><Box fw='bold'>{`#${revision}`}</Box></Tooltip>}
            style={{ cursor: 'default' }}
            zIndex='100' // TODO: change this to var(--mantine-z-index-app) when we upgrade to 7.3
            position='middle-start'
          >
            <NumberCell centered>
              <span>
                <Tooltip label='Created Date' position='top'>
                  <Text>{dayjs(value).format('MM-DD-YYYY')}</Text>
                </Tooltip>
                <Tooltip label={publishedDate ? 'Published Date' : 'Not Published Yet'} position='bottom'>
                  <Text fs='italic'>
                    {publishedDate ? dayjs(publishedDate).format('MM-DD-YYYY') : '-'}
                  </Text>
                </Tooltip>
              </span>
            </NumberCell>
          </Indicator>
        )
      },
      filterable: true,
      sortable: 'multiple',
      hideable: true
    },
    {
      Header: (
        <Header align='center' gap='xs'>
          Respondents
          <Dropdown target={<ActionIcon radius='xl' size='sm' color='gray'><IconInfoCircle /></ActionIcon>}>
            <Text>The number of candidates assigned to all published version(s) of the assessment.</Text>
          </Dropdown>
        </Header>
      ),
      id: 'respondent_count',
      accessor: 'respondent_count',
      headerLabel: 'Respondents',
      Cell: ({ cell: { value } }) => {
        return <Text ta='center'>{value}</Text>
      },
      hideable: true
    }
  ], [])
}

export function useAssessmentActions () {
  const [revisionData, setRevisionData] = useState({ assessments: [], name: '', id: null })
  const [opened, { open, close }] = useDisclosure(false)
  const openModal = useCallback((id, data) => {
    setRevisionData((prev) => prev.id === id
      ? prev
      : ({
          assessments: (data.published_assessments ?? []).toSorted((a, b) => new Date(b.created) - new Date(a.created)),
          name: data.name,
          id: id
        }))
    open()
  }, [open])

  const actions = useMemo(() => {
    return [
      {
        label: 'Edit',
        leftSection: <IconPencil />,
        href: (id) => `/build/assessments/${id}/edit`
      },
      {
        label: 'Preview',
        leftSection: <IconEye />,
        href: (id) => `/build/assessments/${id}/preview`
      },
      {
        label: 'Publish',
        leftSection: <IconCubeSend />,
        href: (id) => `/build/assessments/${id}/publish`
      },
      {
        label: 'Revisions',
        leftSection: <IconReportAnalytics />,
        onClick: (id, assessment) => openModal(id, assessment)
      },
      {
        label: 'Archive',
        leftSection: <IconArchive />,
        href: (id) => `/build/assessments/${id}/archive`
      },
      {
        label: 'Delete',
        leftSection: <IconTrash />,
        variant: 'danger-subtle',
        href: (id) => `/build/assessments/${id}/delete`
      }
    ]
  }, [openModal])

  const modal = (
    <Modal opened={opened} onClose={close} title={`${revisionData.name} Revisions`} size='lg' centered>
      <Box>
        <List>
          <ScrollArea.Autosize mah='20rem'>
            {(revisionData.assessments.length === 0) && (
              <List.Item>No revisions</List.Item>
            )}
            {revisionData.assessments.map((revision, index) => (
              <RevisionListItem key={revision.id} revision={revision} index={index} totalRevisions={revisionData.assessments.length} />
            ))}
          </ScrollArea.Autosize>
        </List>
      </Box>
    </Modal>
  )

  return [actions, modal]
}

function RevisionListItem ({ revision, index, totalRevisions }) {
  return (
    <List.Item>
      <Link to={`/testenv/assessments/${revision.id}/view`} reloadDocument>
        Revision #{ totalRevisions - index } ({ dayjs(revision.created).format('MMMM D, YYYY') })
      </Link>
      {!!revision.cycle_stages?.length && <RevisionCyclesList stages={revision.cycle_stages} />}
    </List.Item>
  )
}

function RevisionCyclesList ({ stages }) {
  const cycles = useMemo(() => {
    const cyclesMap = new Map()
    for (const stage of stages) {
      if (stage.cycle?.id) {
        const cycle = cyclesMap.get(stage.cycle.id)
        if (!cycle) {
          cyclesMap.set(stage.cycle.id, { name: stage.cycle.name, id: stage.cycle.id, stages: [stage], currentStage: stage.stage_replacement ? null : stage })
        } else {
          cycle.stages.push(stage)
          if (!stage.stage_replacement) {
            cycle.currentStage = stage
          }
        }
      }
    }
    return [...cyclesMap.values()]
  }, [stages])

  return (
    <List withPadding>
      {cycles.map((cycle) => (
        <List.Item key={cycle.id}>
          <Link to={ generatePath(CyclePassRoute.CycleStages, { cycleId: cycle.id.toString() })} reloadDocument>
            {cycle.name} ({ '#' + cycle.id }) - {cycle.currentStage ? (cycle.stages.length - 1 || 'no') : cycle.stages.length} Replaced Stages
          </Link>
          {!!cycle.currentStage && (
            <CycleCurrentStageSummary cycle={cycle} />
          )}
        </List.Item>
      ))}
    </List>
  )
}

function CycleCurrentStageSummary ({ cycle }) {
  return (
    <List withPadding>
      <List.Item key={cycle.currentStage.id}>
        <Link to={ generatePath(CyclePassRoute.CycleStageDetail, { cycleId: cycle.id.toString(), stageId: cycle.currentStage.id.toString() })} reloadDocument>
          Active Stage #{ cycle.currentStage.index + 1 }
        </Link>
      </List.Item>
    </List>
  )
}

export function useAssessmentPerPurposeTypes () {
  const [skillBuilderAssessmentTypes, setSkillBuilderAssessmentTypes] = useState([])
  const [hiringAssessmentTypes, setHiringAssessmentTypes] = useState([])
  const types = useAssessmentTypes()

  useEffect(() => {
    if (types.skillbuilder?.length) {
      setSkillBuilderAssessmentTypes([...types.skillbuilder])
    }
    if (types.hiring?.length) {
      setHiringAssessmentTypes([...types.hiring])
    }
  }, [types])

  return [skillBuilderAssessmentTypes, hiringAssessmentTypes]
}

export function useAssessmentCompetencies (params = null) {
  const [competencies, setCompetencies] = useState([])

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getAvailableCompetencies(params ?? {}, cancelSource.token)
      .then(data => {
        if (data) {
          setCompetencies(data.data ?? [])
        }
      })

    return () => cancelSource.cancel()
  }, [params, setCompetencies])

  return competencies
}

/**
 * @param {object} params
 * @param {int|undefined} params.purpose? currently 1-4
 * @param {int|undefined} params.type? should be reset when purpose changes
 * @param {int|undefined} params.protected? currently 0-2
 * @param {int|undefined} params.archived? 0 or 1
 * @param {int|undefined} params.page?
 * @param {int|undefined} params.limit?
 * @param {int|undefined} params.media?
 * @returns {[]} Array of assessments matching params.
 */
export function useAssessments (params) {
  const [assessments, setAssessments] = useState([])
  const [pageLimitTotal, setPageLimitTotal] = useState({ page: params.page ?? 1, limit: params.limit ?? 50, total: 0 })

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getAssessments(params, cancelSource.token).then(collection => {
      if (collection) {
        setAssessments(collection.items)
        setPageLimitTotal((prev) => {
          if ((prev.page === collection.page) && (prev.limit === collection.limit) && (prev.total === collection.total)) {
            return prev
          }
          return { ...prev, page: collection.page, limit: collection.limit, total: collection.total }
        })
      } else {
        console.debug('No assessments from useAssessments hook query - token cancelled?', params, cancelSource)
      }
    })

    return () => {
      console.debug('useAssessments Query Effect canceling on unmount.', params, cancelSource)
      cancelSource.cancel()
    }
  }, [params, setPageLimitTotal])

  return [assessments, pageLimitTotal]
}
