/* eslint react/prop-types: 0 */
import { Checkbox } from '@mantine/core'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSelectedKeys,
  selectBulk,
  selectOne,
  unselectBulk,
  unselectOne
} from './selectedColumnsSlice';

/**
 * Renders a selectable column for the ReactTable. State is managed with redux.
 * @example
 * //  return useMemo(
 * //    () => [
 * //      SelectableColumn({
 * //        selectedValueAccessor: 'checkbox',
 * //        selectAriaLabel: 'Select applicant',
 * //        selectAllAriaLabel: 'Select all applicants',
 * //        id: 'checkbox',
 * //        accessor: 'id',
 * //        metadata: {
 * //          isGranted: account.access.ADMIN
 * //        }
 * //      }),
 * //  ...
 * //  ], [])
 * @param {?string} [selectedKeyAccessor] - The column ID to use as the key for selections. The value of the chosen column must be unique to each row. Defaults to the id passed to this column
 * @param {?string} [selectedValueAccessor] -  The column ID to use as the value. Defaults to the whole row
 * @param {string} [selectAriaLabel] - Accessibility label for each rows' checkbox
 * @param {string} [selectAllAriaLabel] - Accessibility label for the select all checkbox
 * @param props Additional props used by the ReactTable columns (accessor, id, metadata, etc.)
 */
export default function SelectableColumn ({
  selectedKeyAccessor = null,
  selectedValueAccessor = null,
  selectAriaLabel = 'Select this row',
  selectAllAriaLabel = 'Select all rows',
  ...props
}) {
  selectedKeyAccessor = selectedKeyAccessor ?? props.id

  return {
    Header: ({ rows }) => {
      const selected = useSelector((state) => selectAllSelectedKeys(state))
      const keys = rows.map(row => row.original[selectedKeyAccessor].toString())
      const checked = selected.length > 0 && keys.every(key => selected.includes(key))
      const indeterminate = selected.length > 0 && keys.some(key => selected.includes(key))

      return (
        <SelectAllColumn
          rows={rows}
          ariaLabel={selectAllAriaLabel}
          selectedKeyAccessor={selectedKeyAccessor}
          selectedValueAccessor={selectedValueAccessor}
          checked={checked}
          indeterminate={indeterminate && !checked}
        />
      )
    },
    Cell: ({ cell: { row } }) => {
      const selected = useSelector((state) => selectAllSelectedKeys(state))

      return (
        <SelectOneColumn
          checked = {selected.includes(row.original[selectedKeyAccessor].toString())}
          row = {row}
          selectedKeyAccessor={selectedKeyAccessor}
          selectedValueAccessor={selectedValueAccessor}
          ariaLabel = {selectAriaLabel}
        />
      )
    },
    ...props
  }
}

const SelectOneColumn = memo(function SelectOneColumn ({ checked, row, selectedKeyAccessor, selectedValueAccessor, ariaLabel }) {
  const dispatch = useDispatch()

  return (
    <Checkbox
      checked={checked}
      aria-label={ariaLabel}
      onClick={(event) => { event.stopPropagation() }}
      onChange={(event) => event.target.checked
        ? dispatch(selectOne({
          key: row.original[selectedKeyAccessor].toString(),
          value: selectedValueAccessor ? row.original[selectedValueAccessor] : row.original
        }))
        : dispatch(unselectOne({
          key: row.original[selectedKeyAccessor].toString()
        }))
      }
    />
  )
})

const SelectAllColumn = memo(function SelectAllColumn ({ checked, indeterminate, rows, selectedKeyAccessor, selectedValueAccessor, ariaLabel }) {
  const dispatch = useDispatch()

  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      aria-label={ariaLabel}
      onClick={(event) => { event.stopPropagation() }}
      onChange={(event) => event.target.checked
        ? dispatch(selectBulk({
          rows: rows.map(row => row.original),
          keyAccessor: selectedKeyAccessor,
          valueAccessor: selectedValueAccessor
        }))
        : dispatch(unselectBulk({
          keys: rows.map(row => row.original[selectedKeyAccessor].toString())
        }))
    }
    />
  )
})

SelectableColumn.propTypes = {
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  selectAriaLabel: PropTypes.string,
  selectAllAriaLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired
}

SelectAllColumn.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  ariaLabel: PropTypes.string
}

SelectOneColumn.propTypes = {
  checked: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  selectedKeyAccessor: PropTypes.string,
  selectedValueAccessor: PropTypes.string,
  ariaLabel: PropTypes.string
}
