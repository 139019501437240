import {
  createSlice,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'
import { QueryState } from './util';
import { previousQuestionAnswered, fetchPublishedAssessmentQuestions } from './assessmentsSlice';
import {
  selectRespondentAnswerMap, updateVisibleQuestions
} from './respondentAnswersSlice';

/**
 * @type {EntityAdapter<{PublishedQuestion}, int>}
 */
const questionsAdapter = createEntityAdapter()

const initialState = questionsAdapter.getInitialState({
  status: QueryState.Idle,
  error: null,
  showReviewQuestionLength: 5
})

const questionsSlice = createSlice({
  name: 'questions',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVisibleQuestions, (state, action) => {
        const { data } = action.payload
        console.debug('updateVisibleQuestions in questionsSlice', data, action, state)
        for (const [id, hide] of data) {
          const question = state.entities[id]
          if (question?.id && question.hideFromLogic !== hide) {
            console.info('Updating question hide state.', id, question.hideFromLogic, hide)
            question.hideFromLogic = hide
          }
        }
      })
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('question slice loading initial state from assessment response', state, action)
        questionsAdapter.upsertMany(state, action.payload.questions)
        const defaultShowCount = 5
        const answerableQuestionLength = action.payload.questions?.filter(question => question.answerable).length ?? ((defaultShowCount + 1) * 2)
        const halfAnswerableQuestions = Math.floor(answerableQuestionLength / 2)
        state.showReviewQuestionLength = Math.min(
          defaultShowCount,
          halfAnswerableQuestions
        )
        console.debug(
          'Set new minimum question count before displaying review panel. Actual | default | fallback',
          state.showReviewQuestionLength,
          defaultShowCount,
          halfAnswerableQuestions
        )
      })
      .addCase(previousQuestionAnswered, (state, action) => {
        const { questionId: id } = action.payload
        const existingQuestion = state.entities[id]
        console.info('Checking if question should be displayed from previous question answered.', action.payload, id, existingQuestion?.show)
        if (existingQuestion) {
          if (!existingQuestion.show) {
            existingQuestion.show = true
          }
        }
      })
  }
})

// export const { } = questionsSlice.actions

export default questionsSlice.reducer

export const { selectAll: selectAllQuestions, selectById: selectQuestionById, selectIds: selectQuestionIds, selectEntities: selectQuestionsMap } =
  questionsAdapter.getSelectors(state => state.questions)

export const selectPreviousQuestionShowing = createSelector(
  [selectQuestionById],
  (question) => question ? question.show : false
)

export const selectPreviousHidingFromLogic = createSelector(
  [selectQuestionById],
  (question) => question ? question.hideFromLogic : false
)

export const selectAnswerableQuestions = createSelector(
  [selectAllQuestions],
  (
    questions
  ) => questions.filter(question => !question.hideFromLogic && question.answerable)
)

export const selectAllAnswerableQuestionsResponses = createSelector(
  [selectAnswerableQuestions, selectRespondentAnswerMap],
  (
    questions,
    answersMap
  ) => questions.map(question => answersMap[question.id])
)

export const selectShownAnswerableQuestions = createSelector(
  [selectAnswerableQuestions],
  (
    questions
  ) => questions.filter(question => question.show)
)

export const selectSeenQuestionPercentage = createSelector(
  [selectAnswerableQuestions, selectShownAnswerableQuestions],
  (
    answerable, shown
  ) => (shown.length > 1 ? shown.length : 0) / (answerable.length ? answerable.length : 1)
)

export const selectShownAnswerableQuestionsResponses = createSelector(
  [selectShownAnswerableQuestions, selectRespondentAnswerMap],
  (
    questions,
    answersMap
  ) => questions.map(question => answersMap[question.id])
)

export const selectAnsweredQuestionIdsAlreadyDisplayedToUser = createSelector(
  [selectShownAnswerableQuestionsResponses],
  (responses) => responses.filter(answer => answer?.answered).map(answer => answer.id)
)

export const selectShownNotAnsweredQuestionsResponses = createSelector(
  [selectShownAnswerableQuestionsResponses],
  (responses) => responses.filter(answer => !answer?.answered)
)

export const selectDeclinedQuestionIdsAlreadyDisplayedToUser = createSelector(
  [selectShownNotAnsweredQuestionsResponses],
  (responses) => responses.filter(answer => answer?.declined).map(answer => answer.id)
)

export const selectNotAnsweredNotSkippedQuestionIdsAlreadyDisplayedToUser = createSelector(
  [selectShownNotAnsweredQuestionsResponses],
  (responses) => responses.filter(answer => !answer?.skipped && answer?.id).map(answer => answer.id)
)

export const selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUser = createSelector(
  [selectShownNotAnsweredQuestionsResponses],
  (responses) => responses.filter(answer => answer?.skipped && !answer?.declined).map(answer => answer.id)
)

export const selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUserCount = createSelector(
  [selectAnswerableSkippedQuestionIdsAlreadyDisplayedToUser],
  (
    questionResults
  ) => questionResults?.length ?? 0
)

export const selectShowingManyQuestions = createSelector(
  [selectShownAnswerableQuestions, (state) => state.questions.showReviewQuestionLength],
  (
    questions,
    minShowLength
  ) => questions.length >= (minShowLength ?? 5)
)

export const selectNotAnsweredNotDeclinedQuestionsResponses = createSelector(
  [selectAllAnswerableQuestionsResponses],
  (
    responses
  ) => responses.filter(answer => answer?.id && !answer.answered && !answer.declined)
)

export const selectHasAnyUnansweredNotHiddenByLogicQuestions = createSelector(
  [selectNotAnsweredNotDeclinedQuestionsResponses],
  (responses) => !!responses.length
)

export const selectHasAnyUnansweredNotSkippedNotHiddenByLogicQuestions = createSelector(
  [selectNotAnsweredNotDeclinedQuestionsResponses],
  (responses) => !!(responses.filter(answer => !answer.skipped).length)
)

export const selectPotentialZoomToTargetIds = createSelector(
  [selectShownAnswerableQuestions, selectRespondentAnswerMap, (state, minAssessmentIndex) => minAssessmentIndex],
  (
    questions,
    respondentAnswersMap,
    minAssessmentIndex
  ) => questions.filter(question => question.location.indexInAssessment > minAssessmentIndex)
    .map(question => respondentAnswersMap[question.id])
    .filter(respondentAnswer => respondentAnswer?.id && !respondentAnswer.answered && !respondentAnswer.declined)
    .map(elem => elem.id)
)
