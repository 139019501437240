/* eslint react/prop-types: 0 */
import { ActionIcon, Group, Indicator, SimpleGrid, Tooltip } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import { IconDeviceMobileMessage, IconMail, IconPlus } from '@tabler/icons-react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'
import React from 'react'
import { useAccount } from '../../util/Hooks'
import Email from './Email'
import TextMessage from './TextMessage'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export default function Messages ({ form, templates, textTemplates, previewApplicant }) {
  return (
    <SimpleGrid cols={1}>
      {form.values.messages.map((field, index) => (
        <div key={field.key}>
          {field.type === 'email' && <Email form={form} index={index} templates={templates} previewApplicant={previewApplicant}/>}
          {field.type === 'text' && <TextMessage form={form} index={index} templates={textTemplates} previewApplicant={previewApplicant}/>}
        </div>
      ))}
      <Group spacing='xs'>
        <AddEmailMessage form={form}/>
        <AddTextMessage form={form}/>
      </Group>
    </SimpleGrid>
  )
}

function AddEmailMessage ({ form }) {
  return (
    <Tooltip label='Add email'>
      <Indicator color='success' variant='transparent' offset={2} position='bottom-end' label={<IconPlus size='1rem' strokeWidth='4' />}>
        <ActionIcon
          variant='light'
          color='success'
          onClick={() => form.insertListItem(
            'messages',
            {
              key: randomId(),
              type: 'email',
              send_at: null,
              template: null,
              subject: '',
              body: ''
            })
          }
          >
            <IconMail/>
        </ActionIcon>
      </Indicator>
    </Tooltip>
  )
}

function AddTextMessage ({ form }) {
  const account = useAccount()

  return (
    !_.isEmpty(_.find(account?.roles ?? [], { name: 'ROLE_BETA_TESTER' })) &&
    <Tooltip label='Add text message'>
      <Indicator color='success' variant='transparent' position='bottom-end' offset={2} label={<IconPlus size='1rem' strokeWidth='4' />}>
        <ActionIcon
          variant='light'
          color='success'
          onClick={() => form.insertListItem(
            'messages',
            {
              key: randomId(),
              type: 'text',
              send_at: dayjs().add(1, 'day').toDate(),
              template: null
            })
          }
          >
            <IconDeviceMobileMessage/>
        </ActionIcon>
      </Indicator>
    </Tooltip>
  )
}
