/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSelectedValues,
  selectHasAnyRowsSelected,
  unselectAll
} from '../../core/ReactTable/selectedColumnsSlice';
import {
  ActionIcon,
  Affix,
  Box, Button,
  Center, Checkbox, Grid,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
  Title,
  Tooltip,
  Transition
} from '@mantine/core';
import { animated, to, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import styles from '../../layout/animations/styles.module.css';
import { useParams } from 'react-router-dom';
import { useDisclosure, useHover } from '@mantine/hooks';
import { useBulkCommentMutation, useEditStatusMutation } from '../../../redux/query/hire/applicantsApi.slice';
import { notifications } from '@mantine/notifications';
import { IconMessageChatbot, IconStatusChange } from '@tabler/icons-react';
import NoticeAlert from '../../core/Alert/NoticeAlert';
import { useCycleStatuses } from './CycleHooks';
import dayjs from 'dayjs';
import { DatePicker } from '@mantine/dates';

export const DetailViewSelectedHandler = memo(function DetailViewSelectedHandler ({ account }) {
  const dispatch = useDispatch()
  const anySelected = useSelector(state => selectHasAnyRowsSelected(state))
  const [loaded, setLoaded] = useState(!anySelected)

  console.debug('DetailViewSelectedHandler updating', { anySelected, loaded, account })

  useEffect(() => {
    // Necessary because no namespace in selectable column state.
    if (!loaded) {
      dispatch(unselectAll())
      setLoaded(true)
    }
  }, [loaded, dispatch])

  return (
    <>
      {!!loaded && (
        <Affix position={{ top: '50vh', left: '20vw' }}>
          <Transition transition='slide-up' duration={150} exitDuration={50} mounted={!!anySelected} keepMounted>
            {(transitionStyles) => (
              <Box style={transitionStyles}>
                <BulkActionsControl account={account} />
              </Box>
            )}
          </Transition>
        </Affix>
      )}
    </>
  )
})

const springConfigParams = {
  scale: 1,
  zoom: 0,
  x: 0,
  y: 0,
  config: { mass: 5, tension: 350, friction: 60 }
}

const noTextDragStyle = { userSelect: 'none' }

const BulkActionsControl = memo(function BulkActionsControl ({ account }) {
  const [hovered, setHovered] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [everDragged, setEverDragged] = useState(false)
  const domTarget = useRef(null)
  const springConfig = useCallback(() => {
    return springConfigParams
  }, [])

  const [{ x, y, zoom, scale }, api] = useSpring(
    springConfig
  )

  useGesture(
    {
      onDrag: (state) => {
        const { active, canceled, offset: [x, y] } = state
        console.debug('Called BulkActionsControl on drag', { active, x, y, canceled, state })
        api({ x, y })
        setDragging(active)
      },
      onMove: ({ dragging }) => {
        if (dragging !== undefined) {
          if (!dragging) {
            api({
              scale: 1.1
            })
          }
        }
      },
      onHover: ({ hovering }) => {
        if (hovering !== undefined) {
          if (!hovering) {
            api({ scale: 1 })
          }
          setHovered(hovering)
        }
      }
    },
    { target: domTarget, drag: { threshold: 10 }, eventOptions: { passive: false } }
  )

  useEffect(() => {
    if (dragging && !everDragged) {
      setEverDragged(true)
    }
  }, [dragging, everDragged])

  const canEditStatus = !!(account.access.DEVELOPER || account.access.ADMIN || account.access.PORTAL_CLIENT)
  console.debug('BulkActionsControl updating', { x, y, hovered, dragging, canEditStatus })
  return (
    <Box pos='relative'>
      <Tooltip label='Drag to Move' opened={hovered && !everDragged && !dragging} disabled={everDragged || dragging}>
        <animated.div
          ref={domTarget}
          className={styles.bulk}
          style={{
            transform: 'perspective(600px)',
            x: x,
            y: y,
            scale: to([scale, zoom], (s, z) => Math.max(s + z, -s + Math.abs(z), 0.5))
          }}
        >
          <Paper>
            <Stack maw='10rem' gap='xs' justify='flex-start'>
              <Title ta='center' order={4} style={noTextDragStyle}>Bulk Actions</Title>
              {!!canEditStatus && (<BulkStatusControl disabled={dragging} />)}
              <BulkCommentControl disabled={dragging} />
            </Stack>
          </Paper>
        </animated.div>
      </Tooltip>
    </Box>
  )
})

const noStatusOptions = []
const hiredTierId = 2

const BulkStatusControl = memo(function BulkStatusControl ({ disabled }) {
  const dispatch = useDispatch()
  const { cycleId } = useParams()
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state))
  const [opened, { open, close }] = useDisclosure(false)
  const [dateModalOpened, { open: openDateModal, close: closeDateModal }] = useDisclosure(false)
  const [currentValue, setCurrentValue] = useState(null)
  const [statuses] = useCycleStatuses(cycleId)
  const [edit, { isLoading: processing }] = useEditStatusMutation()

  const statusOptions = useMemo(() => {
    if (!statuses?.items?.length) {
      return noStatusOptions
    }
    return statuses.items.map(status => ({ value: status.name, label: status.name, meta: status }))
  }, [statuses])

  const clearAndClose = useCallback(() => {
    setCurrentValue(null)
    closeDateModal()
    close()
  }, [close, closeDateModal])

  const onModalClose = useCallback(() => {
    console.debug('Called bulk status modal close')
    closeDateModal()
    setCurrentValue(null)
  }, [closeDateModal])

  const onModalSave = useCallback((hiredDate) => {
    console.debug('Called bulk status modal save', { hiredDate, currentValue, selectedApplicants })
    edit({
      status: currentValue,
      applicants: selectedApplicants.map(row => row.id),
      hiredDate: hiredDate,
      cycleId: cycleId
    })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Bulk Status Success',
          message: 'The selected candidates have all been updated with their new status!',
          autoClose: 5000,
          color: 'green'
        })
        setCurrentValue(null)
        close()
        closeDateModal()
        dispatch(unselectAll())
      })
      .catch((e) => {
        console.error('Bulk status error response', { e })
        notifications.show({
          title: 'Bulk Status Error',
          message: 'Failed to update status for selected candidates.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [currentValue, selectedApplicants, cycleId, closeDateModal, close, dispatch, edit])

  const onSelect = useCallback((newValue, selectedOption) => {
    console.debug('Called on bulk status select', { newValue, selectedOption, currentValue })
    if (newValue) {
      if (newValue !== currentValue) {
        setCurrentValue(newValue)
        if (selectedOption?.meta?.tier?.id === hiredTierId) {
          openDateModal()
        }
      }
    }
  }, [currentValue, openDateModal])

  console.debug('BulkStatusControl updating', { selectedApplicants, opened, dateModalOpened, currentValue, statusOptions })

  return (
    <div>
      <Center>
        <Tooltip label='Change Statuses' disabled={disabled}>
          <ActionIcon
            variant="outline"
            aria-label="Change Statuses"
            size="xl"
            disabled={!selectedApplicants.length || disabled}
            onClick={open}
            color='blue'
          >
            <IconStatusChange />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Modal opened={opened} onClose={clearAndClose} title='Bulk Status Edit' size='xl'>
        <Box>
          <NoticeAlert title='Choose status for selected candidates below' />
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <Select data={statusOptions} value={currentValue} onChange={onSelect} disabled={processing} />
            <SelectedApplicantsList selectedApplicants={selectedApplicants} />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            <Button onClick={() => onModalSave('')} color='success' loading={processing} disabled={!currentValue}>Save</Button>
            <Button onClick={clearAndClose} color='gray' loading={processing}>Cancel</Button>
          </Group>
          <BulkStatusHiredDateModal opened={dateModalOpened} onClose={onModalClose} onSave={onModalSave} processing={processing} />
        </Box>
      </Modal>
    </div>
  )
})

function BulkStatusHiredDateModal ({ opened, onClose, onSave, processing = false }) {
  const [value, setValue] = useState(new Date())

  const onClick = () => {
    onSave(dayjs(value).local().format('YYYY-MM-DD'))
  }
  console.debug('StatusCellHiredDateModal updating', { value, opened })
  return (
    <Modal opened={opened} onClose={() => onClose(false)} title='Info Required!'>
      <Box>
        <NoticeAlert title='Please select the hire date' />
        <Center><DatePicker value={value} onChange={setValue} disabled={processing} /></Center>
        <Space h='md' />
        <Group justify='center' grow>
          <Button onClick={onClick} color='success' loading={processing}>Save</Button>
          <Button onClick={onClose} color='gray' loading={processing}>Cancel</Button>
        </Group>
      </Box>
    </Modal>
  )
}

const BulkCommentControl = memo(function BulkCommentControl ({ disabled }) {
  const dispatch = useDispatch()
  const { cycleId } = useParams()
  const selectedApplicants = useSelector(state => selectAllSelectedValues(state))
  const [opened, { open, close }] = useDisclosure(false)
  const [content, setContent] = useState('')
  const [pinned, setPinned] = useState(true)
  const [bulkComment, { isLoading: processing }] = useBulkCommentMutation()

  const clearAndClose = useCallback(() => {
    setContent('')
    setPinned(true)
    close()
  }, [close])

  const save = useCallback(() => {
    bulkComment({ applicants: selectedApplicants.map(row => row.id), content: content, pinned: pinned, cycleId: cycleId })
      .unwrap()
      .then(() => {
        notifications.show({
          title: 'Bulk Comment Success',
          message: 'Your comment has been added to all selected candidates!',
          color: 'green',
          autoClose: 5000
        })
        setContent('')
        setPinned(true)
        close()
        dispatch(unselectAll())
      })
      .catch((e) => {
        console.error('Bulk comment error response', { e })
        notifications.show({
          title: 'Bulk Comment Error',
          message: 'Failed to comment selected candidates.',
          color: 'red',
          autoClose: 7000
        })
      })
  }, [selectedApplicants, content, pinned, cycleId, close, dispatch, bulkComment])

  console.debug('BulkCommentControl updating', { selectedApplicants, opened, content, pinned })

  return (
    <div>
      <Center>
        <Tooltip label='Bulk Comment' disabled={disabled}>
          <ActionIcon
            variant="outline"
            aria-label="Bulk Comment"
            size="xl"
            disabled={!selectedApplicants.length || disabled}
            onClick={open}
            color='blue'
          >
            <IconMessageChatbot />
          </ActionIcon>
        </Tooltip>
      </Center>
      <Modal opened={opened} onClose={clearAndClose} title='Bulk Comment' size='xl'>
        <Box>
          <NoticeAlert title='Comment selected candidates below' />
          <Group justify='space-between' grow wrap='nowrap' align='flex-start'>
            <Box>
              <Textarea
                label="Bulk Comment"
                value={content}
                onChange={(event) => setContent(event.currentTarget.value)}
                autosize
                minRows={5}
                maxRows={10}
              />
              <Space h='xs' />
              <Checkbox
                label='Pinned'
                checked={pinned}
                aria-label='Pin comment'
                onChange={(event) => setPinned(event.currentTarget.checked)}
                disabled={processing}
              />
            </Box>
            <SelectedApplicantsList selectedApplicants={selectedApplicants} />
          </Group>
          <Space h='md' />
          <Group justify='center' grow>
            <Button onClick={save} color='success' loading={processing} disabled={!content.trim()}>Save</Button>
            <Button onClick={clearAndClose} color='gray' loading={processing}>Cancel</Button>
          </Group>
        </Box>
      </Modal>
    </div>
  )
})

const SelectedApplicantsList = memo(function SelectedApplicantsList ({ selectedApplicants }) {
  return (
    <Paper shadow="md" radius="md" p="md" maw='100%' withBorder>
      <Title order={5} ta='center'>Selected Candidates</Title>
      <ScrollArea.Autosize mah='50vh' scrollbars='y' type='auto'>
        <Box pos='relative'>
          {selectedApplicants.map((applicant) => <SelectedApplicantsListItem key={applicant.id} applicant={applicant} />)}
        </Box>
      </ScrollArea.Autosize>
    </Paper>
  )
})

const listItemStyle = { userSelect: 'none', cursor: 'pointer' }

const SelectedApplicantsListItem = memo(function SelectedApplicantsListItem ({ applicant }) {
  const { hovered, ref } = useHover()
  const lastName = (applicant.first_name && applicant.last_name) ? ' ' + applicant.last_name : (applicant.last_name ?? '')
  const applicantName = (applicant.first_name ?? '') + lastName

  return (
    <div style={listItemStyle} ref={ref}>
      <Paper
        bg={hovered ? 'gray.2' : 'gray.1'}
        mt='xxs'
        mb={0}
        mx='sm'
        shadow='xs'
        radius='xs'
        p='sm'
      >
        <Grid columns={24} align='center'>
          <Grid.Col span={15}>
            <Text ta='left' fw={750} size='md' truncate='end'>{applicantName}</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <Text ta='left' size='sm' truncate='end'>{applicant.status?.name ?? 'Status Missing'}</Text>
          </Grid.Col>
        </Grid>
      </Paper>
    </div>
  )
})
