/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState } from 'react'
import { Text } from '@mantine/core'
import { IconMail, IconMailShare } from '@tabler/icons-react'
import { useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { Header, NumberCell, RezviewCell } from '../../../core/ReactTable/ReactTable';
import SelectableColumn from '../../../core/ReactTable/SelectableColumn';
import {
  getCycleInviteLimitInformation,
  getCycleTemplates
} from '../../../../js/api/cycle_repository';
import { FormComponentType } from '../../../core/ReactTable/FormFilters';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../core/ReactTable/paramsSlice';
import { apiSlice } from '../../../api';
import { useGetBoostInvitableApplicantsQuery } from './BoostApi';
import { useJobsQuery } from '../../jobs/JobHooks';
import { isGranted } from '../../../../util/account/account.util';

export function useBoostQuery (namespace, id, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetBoostInvitableApplicantsQuery({ ...queryParams, id }, { skip })
  return [collection ?? null, querying]
}

export function useLazyBoostQuery (namespace, id) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying, error } = apiSlice.endpoints.getBoostInvitableApplicants.useQueryState({ ...queryParams, id })
  return [collection ?? null, querying, error ?? null]
}

const defaultFilters = {}
const defaultHiddenColumns = []

export function useBoostTable (account, job, open) {
  const isAdmin = account ? isGranted(account, 'ROLE_ADMIN') : false
  const columns = useBoostColumns(isAdmin)
  const actions = useBoostActions()
  const formFilters = useBoostFormFilters(job)
  const bulkActions = useBoostBulkActions(isAdmin, open)
  const bulkActionsLabel = 'Bulk Actions'

  return {
    defaultFilters,
    defaultHiddenColumns,
    columns,
    actions,
    formFilters,
    bulkActions,
    bulkActionsLabel
  }
}

export function useBoostColumns (isAdmin) {
  return useMemo(
    () => [
      SelectableColumn({
        selectedValueAccessor: 'id',
        selectedKeyAccessor: 'id',
        selectAriaLabel: 'Select applicant',
        selectAllAriaLabel: 'Select all applicants',
        id: 'checkbox',
        accessor: 'id',
        metadata: {
          isGranted: isAdmin
        }
      }),
      {
        Header: <Header>Name</Header>,
        Cell: ({ cell: { value, row } }) => <RezviewCell name={value} applicantId={row.original.id} isAdmin={isAdmin}/>,
        id: 'name',
        accessor: '0.name'
      },
      {
        Header: <Header>Organization</Header>,
        Cell: ({ cell: { value } }) => <Text>{value}</Text>,
        id: 'organization',
        accessor: 'clientName',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>Specific Job</Header>,
        Cell: ({ cell: { value } }) => <Text>{value}</Text>,
        id: 'specificJob',
        accessor: 'specificJob',
        metadata: {
          isGranted: isAdmin
        }
      },
      {
        Header: <Header>General Job</Header>,
        Cell: ({ cell: { value } }) => <Text>{value}</Text>,
        id: 'generalJob',
        accessor: 'generalJob'
      },
      {
        Header: <Header>Address</Header>,
        Cell: ({ cell: { value } }) => <Text>{value.city}, {value.state}</Text>,
        id: 'address',
        accessor: '0.contact_information'
      },
      {
        Header: <Header centered>Baseline</Header>,
        Cell: ({ cell: { value } }) =>
          <NumberCell centered>
            <Text>{Math.floor(value)}</Text>
          </NumberCell>,
        id: 'baseline',
        accessor: 'baseline'
      }
    ].filter(item => item.metadata?.isGranted !== false),
    [isAdmin]
  )
}

export function useBoostBulkActions (isAdmin, open) {
  return useMemo(
    () => [
      {
        label: 'Bulk Invite',
        leftSection: <IconMailShare/>,
        onClick: open,
        metadata: {
          isGranted: isAdmin
        }
      }
    ].filter(item => item.metadata?.isGranted !== false),
    [isAdmin, open])
}

export function useBoostActions () {
  const { cycleId } = useParams()

  return useMemo(
    () => [
      {
        label: 'Invite',
        leftSection: <IconMail />,
        href: (id) => `/cycles/${cycleId}/applicants/${id}/invite`
      }
    ], [cycleId]
  )
}

export function useBoostFormFilters ({ job }) {
  const [jobs, isQuerying] = useJobsQuery({ archived: 0, limit: 0 })

  const formattedJobs = useMemo(() => {
    if (!isQuerying) {
      return jobs.items
        .map(job => job.name)
    }
  }, [jobs, isQuerying])

  return useMemo(() => [
    {
      type: FormComponentType.MultiSelect,
      data: formattedJobs,
      label: 'Jobs',
      id: 'jobs',
      initialValue: job
    },
    {
      type: FormComponentType.Text,
      label: 'Address',
      id: 'address',
      initialValue: ''
    }
  ], [formattedJobs, job])
}

export function useBoostTemplates (cycle) {
  const [templates, setTemplates] = useState([])

  useEffect(() => {
    getCycleTemplates(cycle)
      .then(response => setTemplates(response.data))
      .catch((err) => {
        console.error('Error getting templates:', err)

        showNotification({
          title: 'Something went wrong',
          message: 'There was an error fetching the templates',
          color: 'red',
          autoClose: 3000
        })
      })
  }, [cycle]);

  return templates
}

export function useBoostLimitInformation (cycle) {
  const [limitInformation, setLimitInformation] = useState(null)

  useEffect(() => {
    // Get boost related flashes that were added by the backend
    getCycleInviteLimitInformation(cycle)
      .then((data) => {
        setLimitInformation(data)
      })
      .catch((err) => {
        console.error('Error retrieving invite limit: ', err)

        throw err
      })
  }, [cycle]);

  return limitInformation
}
